




























































































































































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useSettings, emailTypeOptions } from '@/admin/setting';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import draggable from 'vuedraggable';
import consoleMenu from '@/composition/consoleMenu';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'Settings',
  components: {
    draggable,
    FcRoleLoading,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('settings'));

    const settings = useSettings();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const isEdit = ref(false);
    const isEditItem = ref(false);
    const edit = () => {
      isEdit.value = !isEdit.value;
    };
    const tab = 0; // currentになる、タブの初期値
    const tabItems = ['フォーム設定', 'メール', 'コンソールメニュー', 'wysiwyg'];
    const selectedEmailType = ref('preSignup');
    const menuHeaders = computed(() => [
      { text: '', value: 'handle', sortable: false, class: !isEdit.value ? 'd-none' : '', width: 50 },
      { text: '', value: 'icon', sortable: false, class: !isEdit.value ? 'd-none' : '', width: 50 },
      { text: 'アイコン', value: 'icon-pass', sortable: false },
      { text: 'メニュー名', value: 'title', sortable: false },
      { text: '表示', value: 'isActive', sortable: false },
      { text: '遷移先ページ', value: 'to', sortable: false },
    ]);

    const isSaving = ref(false);
    const save = async () => {
      const { isValid, errorMessage } = settings.validate();
      if (!isValid) {
        notification.error(errorMessage);
        return;
      }
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        await settings.saveSettings();
        notification.notify('変更しました。');
        consoleMenu.consoleMenu = settings.settingConsoleMenu.value;
      } catch (error) {
        notification.error(error);
      }
      isEditItem.value = false;
      isSaving.value = false;
    };

    const active = ref(false);
    const menuLink = ref({
      title: '',
      icon: 'mdi-' + '',
      to: '',
      isActive: true,
    });
    const open = () => {
      active.value = true;
    };
    const addMenuLink = async () => {
      if (!(await confirmDialog('追加しますか？'))) return;
      settings.settingConsoleMenu.value.push({ ...menuLink.value });
      active.value = false;
      menuLink.value = { title: '', icon: 'mdi-' + '', to: '', isActive: true };
    };

    const editItem = () => {
      if (isEdit.value) {
        isEditItem.value = true;
      }
    };

    watch(
      () => settings.settingConsoleMenu.value,
      () => editItem()
    );

    return {
      pageTitle: '設定',
      myRoleSettings,
      settings,
      tab,
      save,
      isSaving,
      tabItems,
      isEdit,
      edit,
      selectedEmailType,
      menuHeaders,
      emailTypeOptions,
      addMenuLink,
      menuLink,
      active,
      open,
      isEditItem,
      editItem,
    };
  },
});
